import React from 'react';
import {Route, Routes} from 'react-router-dom';
import CheckoutShell from './Shell';
import CartPage from './cart/Cart';
import TicketingImpulsePage from './ticketing/Impulse';
import VendingImpulsePage from './vending/Impulse';
import TicketingInstantImpulsePage from './ticketing-instant/impulse';
import CartShell from './cart/Shell';
import TicketingSuccessPage from './ticketing/Success';
import VendingSuccessPage from './vending/Success';
import TicketingInstantSuccessPage from './ticketing-instant/success';
import TicketingPaymentPage from './ticketing/Payment';
import VendingPaymentPage from './vending/Payment';
import TicketingInstantPaymentPage from './ticketing-instant/payment';
import Browser from '../../components/Browser';
import CheckoutIntegration from './integration';
import PaymentLinkCompletePage from './payment-link/complete';
import PaymentLinkImpulsePage from './payment-link/impulse';
import PaymentLinkPaymentPage from './payment-link/payment';
import {NotFoundWithShell} from '../../components/not-found';

const CheckoutRoutes = () => {
    return (
        <div className="pages">
            <Routes>
                <Route path='cart' element={<CartShell/>}>
                    <Route index element={<Browser><CartPage/></Browser>}/>
                </Route>
                <Route path='/' element={<CheckoutShell/>}>
                    <Route path='ticketing'>
                        <Route path='impulse' element={<Browser><TicketingImpulsePage/></Browser>}/>
                        <Route path='payment' element={<Browser><TicketingPaymentPage/></Browser>}/>
                    </Route>
                    <Route path='ticketing-instant'>
                        <Route path='impulse' element={<Browser><TicketingInstantImpulsePage/></Browser>}/>
                        <Route path='payment' element={<Browser><TicketingInstantPaymentPage/></Browser>}/>
                    </Route>
                    <Route path='vending'>
                        <Route path='impulse' element={<Browser><VendingImpulsePage/></Browser>}/>
                        <Route path='payment' element={<Browser><VendingPaymentPage/></Browser>}/>
                    </Route>
                    <Route path='payment-link'>
                        <Route path='impulse' element={<Browser><PaymentLinkImpulsePage/></Browser>}/>
                        <Route path='payment' element={<Browser><PaymentLinkPaymentPage/></Browser>}/>
                    </Route>
                </Route>
                <Route path='ticketing'>
                    <Route path='success' element={<TicketingSuccessPage/>}/>
                </Route>
                <Route path='vending'>
                    <Route path='success' element={<VendingSuccessPage/>}/>
                </Route>
                <Route path='ticketing-instant'>
                    <Route path='success' element={<TicketingInstantSuccessPage/>}/>
                </Route>
                <Route path='payment-link'>
                    <Route path='success' element={<PaymentLinkCompletePage/>}/>
                </Route>
                <Route path='integration' element={<CheckoutIntegration/>}/>
                {/* <Route path='payment-link' element={<CheckoutIntegration/>}/> */}
                <Route path='*' element={<NotFoundWithShell/>}/>
            </Routes>
        </div>
    )
};

export default React.memo(CheckoutRoutes);