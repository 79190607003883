import React from 'react';
import NotFoundPage from '../../components/not-found';
import Ogle from '../../components/theme/Ogle';
import {ErrorBoundaryShellWrapper} from '../../components/root/ogle/Shell';

export const ServiceNotFoundWithShell = (theme, actionLink = '/events') => {
    return React.memo(({error}) => {
        if (error?.toString()?.includes('Service invalid')) {
            return (
                <Ogle>
                    <ErrorBoundaryShellWrapper>
                        <div className='view'>
                            <div className='block'>
                                <NotFoundPage {...{theme, actionLink}}/>
                            </div>
                        </div>
                    </ErrorBoundaryShellWrapper>
                </Ogle>
            )
        }
        throw error;
    });
}

const ServiceNotFound = (theme, actionLink = '/events') => {
    return React.memo(({error}) => {
        if (error?.toString()?.includes('Service invalid')) {
            return (
                <div className='view'>
                    <div className='block'>
                        <NotFoundPage {...{theme, actionLink}}/>
                    </div>
                </div>
            )
        }
        throw error;
    });
};



export default ServiceNotFound;