import React from 'react';
import './not-found.scss';
import {Link, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../searchFilter';
import NotFoundDarkSvg from '../assets/not-found-dark.svg';
import NotFoundLightSvg from '../assets/not-found-light.svg';
import {LinkButton, RouterLinkButton} from './button/button';
import {TELEGRAM_SUPPORT} from '../constants';
import Ogle from './theme/Ogle';
import {ErrorBoundaryShellWrapper} from './root/ogle/Shell';

const THEME_SETTINGS = {
    light: {
        accent: 'color-primary-600',
        title: 'color-gray-900',
        description: 'color-gray-500',
        secondAction: 'secondary-gray',
        IconComponent: NotFoundLightSvg
    },
    dark: {
        accent: 'color-primary-500',
        title: 'color-white',
        description: 'color-gray-300',
        secondAction: 'primary-dark',
        IconComponent: NotFoundDarkSvg
    }
};

export const NotFoundWithShell = React.memo(({theme, actionLink}) => {
    return (
        <Ogle>
            <ErrorBoundaryShellWrapper>
                <div className='view'>
                    <div className='block'>
                        <NotFoundPage {...{theme, actionLink}}/>
                    </div>
                </div>
            </ErrorBoundaryShellWrapper>
        </Ogle>
    )
});

const NotFoundPage = ({theme = 'light', actionLink = '/'}) => {
    const location = useLocation();
    const {accent, title, description, secondAction, IconComponent} = THEME_SETTINGS[theme];
    return (
        <div className='not-found-page padding-top-4rem padding-bottom-4rem mw768-padding-top-6rem mw768-padding-bottom-6rem display-flex flex-direction-column-row column-gap-2rem row-gap-2rem'>
            <div className='mw768-order-2 mw768-padding-top-5rem mw768-padding-bottom-5rem'>
                <IconComponent className='width-9dot5rem mw768-width-30rem display-block'/>
            </div>
            <div className='mw768-order-1 flex-1 align-self-center'>
                <p className={`${accent} text-md semibold`}><FormattedMessage defaultMessage='404 error'/></p>
                <h1 className={`${title} display-md semibold padding-top-0dot75rem`}><FormattedMessage defaultMessage='Page not found'/></h1>
                <p className={`${description} text-lg mw768-text-xl padding-top-1rem mw768-padding-top-1dot5rem mw768-max-width-30rem`}>
                    <FormattedMessage defaultMessage="Sorry, the page you are looking for doesn't exist. Here are some helpful links:"/>
                </p>
                <div className='display-flex flex-direction-column-row row-gap-0dot75rem column-gap-0dot75rem padding-top-2rem mw768-padding-top-3rem'>
                    <div className='mw768-order-2'>
                        <RouterLinkButton {...{size: 'xl', color: 'primary-gradient', to: `${actionLink}${searchFilter(location.search)}`, fluid: 'mobile', gtm: 'not-found-home-btn'}}>
                            <FormattedMessage defaultMessage='To main page'/>
                        </RouterLinkButton>
                    </div>
                    <div className='mw768-order-1'>
                        <LinkButton {...{size: 'xl', color: secondAction, link: {href: TELEGRAM_SUPPORT, target: '_blank', gtm: 'not-found-support-link'}, fluid: 'mobile', gtm: 'not-found-support-btn'}}>
                            <FormattedMessage defaultMessage='Support service'/>
                        </LinkButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(NotFoundPage);
