import React from 'react'
import './D.scss';
import {Link, useLocation} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from './../../../Skeleton';
import {CartStateContext, CartDispatchContext} from '../../../../contexts/cart';
import {useNavigate} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import searchFilter from '../../../../searchFilter';
import {Helmet} from 'react-helmet-async';
import Marked from './../../../Marked';
import i18n from '../../../../i18n';
import useQuery from '../../../../useQuery';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {isTrue} from '../../../../utility';
import useNow from '../../../../useNow';
import {adopt} from '../../../../math';
import {IN, ENTITY_TYPE} from '../../../../constants';
import Mini from './../../mini/Mini';
import IconMaster from '../../../../assets/icons-payment/icon-master.svg';
import IconVisa from '../../../../assets/icons-payment/icon-visa.svg';
import IconApplePay from '../../../../assets/icons-payment/icon-apple-pay.svg';
import IconGooglePay from '../../../../assets/icons-payment/icon-google-pay.svg';
import ErrorFallback from '../../../ErrorFallback';
import Browser from '../../../Browser';
import {format} from 'date-fns';
import {getServiceTagValue} from '../../../../utilities/get-tag-value';
import getLocaleQueryParams from '../../../../utilities/get-locale-query-params';

const legal = 'https://www.ottry.com/legal';
const facebookLink = 'https://www.facebook.com/OTTRYcom';
const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';
const year = new Date().getFullYear();

const DEndpoint = React.memo(({endpoint, serviceById}) => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    const navigate = useNavigate();
    const state = React.useContext(CartStateContext);
    const dispatch = React.useContext(CartDispatchContext);
    const fEndpoint = useFragment(
        graphql`
            fragment DEndpoint_endpoint on Endpoint {
                id
                name
                nameUk
                quantity
                price
                description
                descriptionUk
            }
        `,
        endpoint
    );
    const fServiceById = useFragment(
        graphql`
            fragment DEndpoint_serviceById on Service {
                id
            }
        `,
        serviceById
    );
    const count = React.useMemo(() =>
            state
                .filter(e => e.serviceId === fServiceById.id && e.endpointId === fEndpoint.id)
                .reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0),
        [state, fEndpoint.id, fServiceById.id]
    );
    const {more} = useQuery();
    return (
        <div className='margin-top-1rem'>
            <div className='font-weight-bold'>
                {i18n(fEndpoint, 'name', locale)} {fEndpoint.price} UAH{fEndpoint.description ? ` ${i18n(fEndpoint, 'description', locale)}` : ''}
            </div>
            {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{fEndpoint.id}</div>}
            {(fEndpoint.quantity < 16 || isTrue(more)) && <div className='input-warning'>{fEndpoint.quantity} <FormattedMessage defaultMessage='left'/></div>}
            <div className='display-flex margin-top-1rem'>
                {/*<div*/}
                {/*className='button primary'*/}
                {/*onClick={() => dispatch({*/}
                {/*type: 'add',*/}
                {/*payload: {endpointId: fEndpoint.id, serviceId: fServiceById.id}*/}
                {/*})}*/}
                {/*>*/}
                {/*+1 в кошик*/}
                {/*{!!count && <span className='badge margin-left-0dot25rem'>{count}</span>}*/}
                {/*</div>*/}
                <Browser>
                    <div
                        className='button primary'
                        onClick={() => {
                            !count && dispatch({
                                type: 'add',
                                payload: {endpointId: fEndpoint.id, serviceId: fServiceById.id}
                            });
                            navigate(`/checkout-new/cart${searchFilter(location.search)}`);
                        }}
                    >
                        {count ? <FormattedMessage defaultMessage='Already in the cart, go'/> : <FormattedMessage defaultMessage='Buy'/>}
                    </div>
                </Browser>
            </div>
        </div>
    );
});

const DService = ({id}) => {
    const data = useLazyLoadQuery(
        graphql`
            query DQuery($id: String) {
                serviceById(id: $id) {
                    id
                    name
                    nameUk
                    details
                    detailsUk
                    description
                    start
                    end
                    strategy
                    every
                    target
                    endpoints {
                        id
                        quantity
                        price
                        ...DEndpoint_endpoint
                    }
                    ...DEndpoint_serviceById
                }
            }
        `,
        {id}
    );
    const showTicketsBoxBtn = data.serviceById.description && data.serviceById.description.includes('btnAnchorTickets');
    const ticketsBoxRef = React.useRef();
    const scrollToTickets = () => {
        ticketsBoxRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }
    const {locale = 'uk', more} = useQuery();
    const cover = React.useMemo(() => {
        if (i18n(data.serviceById, 'details', locale)) {
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
            const found = i18n(data.serviceById, 'details', locale).match(regex);
            return found && found[0];
        }
    }, [data, locale]);
    const now = useNow();
    const {state} = React.useMemo(() => adopt(now, data.serviceById.start, data.serviceById.end, data.serviceById.strategy,
        data.serviceById.every), [now, data.serviceById]);
    const target = data.serviceById.target ? format(new Date(data.serviceById.target), 'dd-MM-yyyy') : null;
    const lowestTicketPrice = React.useMemo(() => {
        data.serviceById.endpoints ? data.serviceById.endpoints.map(e => e.price).sort() : [];
        if (data.serviceById.endpoints && data.serviceById.endpoints.length) {
            return data.serviceById.endpoints.reduce((price, endpoint) => {
                price = price > endpoint.price ? endpoint.price : price;
                return price;
            }, data.serviceById.endpoints[0].price);
        }
    }, [data]);
    const seoDescription = decodeURI(getServiceTagValue(data.serviceById.description, 'seoDescription', {defaultValue: ''}));
    const seoTitle = decodeURI(getServiceTagValue(data.serviceById.description, 'seoTitle', {defaultValue: ''}));
    const defaultDescription = `Придбати квиток на ⭐️ ${i18n(data.serviceById, 'name', locale)}${target ? ` ☝${target}` : ''}${lowestTicketPrice ? `. Від ${lowestTicketPrice} грн` : ''} | Купити квитки онлайн`;    
    return (
        <>
        <Helmet>
            <title>{seoTitle || `${i18n(data.serviceById, 'name', locale)}: Купити квитки на ottry.com`}</title>
            <meta name='description' content={seoDescription || defaultDescription}/>
            <meta property='og:image' content={cover}/>
        </Helmet>
        <div>
            {showTicketsBoxBtn &&
                <div className='position-absolute top-1dot5rem right-1rem'>
                    <div className='button primary' onClick={() => scrollToTickets()}><FormattedMessage defaultMessage='Tickets'/></div>
                </div>
            }
            <div className='view'>
                <div className='block'>
                    <h1 className='font-size-2rem font-weight-bold text-align-center margin-bottom-1rem'>
                        {i18n(data.serviceById, 'name', locale)}
                    </h1>
                    <Marked {...{content: i18n(data.serviceById, 'details', locale)}}/>
                    <div className="display-flex flex-wrap-wrap">
                        <div className="flex-grow-1 flex-basis-300px margin-bottom-1rem" ref={ticketsBoxRef}>
                            {i18n(data.serviceById, 'details', locale) && i18n(data.serviceById, 'details', locale).endsWith('!') ?
                                <>
                                    <p><FormattedMessage defaultMessage='Sales were closed'/></p>
                                </>
                                :
                                <>
                                <Mini {...{
                                    start: data.serviceById.start,
                                    end: data.serviceById.end,
                                    strategy: data.serviceById.strategy,
                                    every: data.serviceById.every,
                                    entityType: ENTITY_TYPE.service
                                }}/>
                                {state === IN &&
                                <div>
                                {data.serviceById.endpoints.map((endpoint, index) =>
                                    <div key={endpoint.id}>
                                        {(
                                            more ||
                                            (
                                                (
                                                    index === 0 ||
                                                    (index > 0 && data.serviceById.endpoints[index -1].quantity < 16)
                                                ) && data.serviceById.endpoints[index].quantity > 0
                                            )
                                        ) && <DEndpoint endpoint={endpoint} serviceById={data.serviceById}/>
                                        }
                                    </div>
                                )}
                                {!data.serviceById.endpoints.some(e => e.quantity) && <div>Квитки закінчилися</div>}
                                </div>
                                }
                                </>
                            }
                        </div>
                    </div>
                    <br/>
                    <p className='font-size-0dot8rem'><FormattedMessage defaultMessage='* After payment, a letter with a QR code will be sent to your e-mail address, which must be presented at the entrance. Please note that in the case of using the Gmail mail service, the letter with the ticket may end up in the "Alerts", "Promotions" or "Social networks" folder.'/></p>
                </div>
            </div>
        </div>
        </>
    )
};

const D = ({id}) => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <div className='d'>
            <div className='d__header'>
                <Link to={`/events${searchFilter(location.search)}`}><span className='d__accent font-weight-bold'>Tickets</span> by Ottry</Link>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={
                    <div className='view'>
                        <div className='block'>
                            <Skeleton/>
                        </div>
                    </div>
                }>
                    <DService {...{id}}/>
                </React.Suspense>
            </ErrorBoundary>
            <div className='d__footer'>
                <div className='d__footer-line'>
                    <div className='d__footer-line-left'>&nbsp;</div>
                    <Link to={`/${searchFilter(location.search)}`}>Ottry</Link>
                    <div className='d__footer-line-right'>&nbsp;</div>
                </div>
                <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                    <a
                        className={classNames('font-weight-bold', 'margin-right-0dot5rem', {'font-size-1dot2rem': locale === 'uk'})}
                        href={`${location.pathname}${getLocaleQueryParams(location.search, 'uk')}`}
                    >Uk</a>
                    {' '}
                    <a
                        className={classNames('font-weight-bold', {'font-size-1dot2rem': locale === 'en'})}
                        href={`${location.pathname}${getLocaleQueryParams(location.search, 'en')}`}
                    >En</a>
                </div>
                <div className='d__payment margin-bottom-1rem'>
                    <IconMaster className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                    <IconVisa className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                    <IconApplePay className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                    <IconGooglePay className='display-block height-2rem mw768-height-2dot5rem'/>
                </div>
                <div className='d__footer-content'>
                    <div className='d__footer-info'>
                        <a
                            href={formLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        ><FormattedMessage defaultMessage='I want to sell online'/></a>
                        <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
                            <FormattedMessage defaultMessage='Report an issue'/>
                        </a>
                        <Link to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                        <Link to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                        <Link to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                    </div>
                </div>
                <div className='d__footer-rights'>
                    © {year} Ottry. All rights reserved.
                </div>
            </div>
        </div>
    );
};

export default React.memo(D);