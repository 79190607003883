import React from 'react';
import {Link, useParams} from 'react-router-dom';
import QRCode from 'qrcode.react';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import XCloseIcon from '../../assets/icons0/x-close.svg';
import LogoMark from '../../assets/logo-mark.svg';
import ChevronLeft from '../../assets/icons/chevron-left.svg';
import ChevronRight from '../../assets/icons/chevron-right.svg';
import ShareIcon from '../../assets/icons0/share-01.svg'
import DownloadIcon from '../../assets/icons0/download-01.svg';
import useQuery from '../../useQuery';
import i18n from '../../i18n';
import './Ticket.scss'
import moment from 'moment';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import qs from 'qs';

const RAZZLE_APP_PREFIX = process.env.RAZZLE_APP_PREFIX;

const translations = {
    email: {
        name: 'Email',
        nameUk: 'Ел. пошта'
    },
    download: {
        name: 'Download',
        nameUk: 'Скачати'
    }
}

const QrCard = ({qr, impulse, index, qrsAmount, endpointIndex, qrIndex}) => {
    const {locale = 'uk'} = useQuery();
    const endpoint = impulse.impulseEndpoints[endpointIndex];
    const phoneAddl = impulse.impulseAddls ? impulse.impulseAddls.find(item => item.name === 'Phone') : null;
    const scaned = endpoint.numbers ? endpoint.numbers[qrIndex] && endpoint.numbers[qrIndex] > 0 : false;
    const webShareSupported = navigator && 'canShare' in navigator;

    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation TicketQrsQDownloadMutation {
                handle {
                    accessToken
                }
            }
    `);

    const downloadQr = () => {
        if (!isMutationInFlight) {
            commit({
                onCompleted: (data) => {
                    const suffix = qs.stringify({
                        handle: data.handle.accessToken,
                        id: impulse.id,
                        position: endpointIndex,
                        index: qrIndex
                    });
                    window.location.href = RAZZLE_APP_PREFIX + `/api/impulses/plant/download?` + suffix;
                },
                onError: () => {}
            })
        }
    }

    const shareQr = async () => {
        if (webShareSupported) {
            try {
                if (!isMutationInFlight) {
                    commit({
                        onCompleted: async (data) => {
                            const suffix = qs.stringify({
                                handle: data.handle.accessToken,
                                id: impulse.id,
                                position: endpointIndex,
                                index: qrIndex
                            });
                            const response = await fetch(`${RAZZLE_APP_PREFIX}/api/impulses/plant/download?${suffix}`);
                            const blob = await response.blob();
                            const filesArray = [
                                new File(
                                    [blob],
                                    `${impulse.id}-${endpointIndex}-${qrIndex}.png`,
                                    {
                                        type: blob.type,
                                        lastModified: new Date().getTime()
                                    }
                                )
                            ];
                            const shareData = {
                                files: filesArray,
                                title: `${i18n(impulse.impulseService, 'name', locale)} | ${i18n(endpoint, 'name', locale)}`
                            };
                            await navigator.share(shareData);
                        },
                        onError: () => {}
                    })
                }
                
            } catch (error) {
                console.log('Sharing failed!', error)
            }
        }
    }

    return (
        <div className='padding-top-1rem padding-bottom-1rem border-radius-0dot75rem qr-card position-relative'>
            <div className='position-absolute qr-card-logo'>
                <LogoMark className='display-block height-5rem width-5rem padding-1rem'/>
            </div>
            <div className='padding-left-1rem padding-right-1rem text-align-center'>
                <div className='display-flex justify-content-flex-end'>
                    <div className='flex-1'>
                        {!!scaned && 
                            <div className='background-color-primary-50 border-radius-0dot375rem padding-top-0dot125rem padding-bottom-0dot125rem padding-left-0dot625rem padding-right-0dot625rem text-sm medium color-primary-700 width-fit-content'>
                                <span><FormattedMessage defaultMessage='Scaned'/></span>
                            </div>
                        }
                    </div>
                    <div className='background-color-gray-100 border-radius-0dot375rem padding-top-0dot125rem padding-bottom-0dot125rem padding-left-0dot625rem padding-right-0dot625rem text-sm medium color-gray-500'>
                        <span>{index + 1}</span>
                        <span> <FormattedMessage defaultMessage='from'/> </span>
                        <span>{qrsAmount}</span>
                    </div>
                </div>
                <div className='padding-top-1dot5rem padding-bottom-1dot25rem mw768-padding-bottom-1dot5rem'>
                    <p className='text-md semibold olor-gray-900'>{i18n(endpoint, 'name', locale)} {endpoint.price || (endpoint.untie && endpoint.price)} <FormattedMessage defaultMessage='UAH'/></p>
                </div>
                <div className='padding-bottom-0dot75rem'>
                    <QRCode size={170} value={`plant://${qr}`}/>
                </div>
                <p className='text-xs color-gray-500 padding-bottom-1rem mw768-padding-bottom-1dot75rem'>{impulse.id}</p>
            </div>
            <div className='qr-card-divider'></div>
            <div className='padding-left-1rem padding-right-1rem padding-top-1rem mw768-padding-top-1dot875rem display-flex justify-content-space-between padding-bottom-1dot5rem'>
                <div className='padding-right-1rem'>
                    <p className='text-xs color-gray-500'>
                        {i18n(translations.email, 'name', locale)}
                    </p>
                    <p className='text-xs medium color-gray-700'>
                        {impulse.impulseClient.email}
                    </p>
                </div>
                {phoneAddl &&
                    <div className=''>
                        <p className='text-xs color-gray-500'>
                            <FormattedMessage defaultMessage='Phone'/>
                        </p>
                        <p className='text-xs medium color-gray-700'>
                            {phoneAddl.value}
                        </p>
                    </div>
                }
            </div>
            <div className='padding-left-1rem padding-right-1rem display-flex column-gap-0dot75rem'>
                <div className='flex-1'>
                    <button onClick={shareQr} className={classNames('padding-top-0dot625rem padding-bottom-0dot625rem display-flex border-radius-0dot5rem card-btn-shadow width-100percent justify-content-center', {'cursor-pointer background-color-gray-100 color-gray-700': webShareSupported})} disabled={!webShareSupported}>
                        <ShareIcon className='display-block width-1dot25rem'/>
                        <p className='text-sm medium padding-left-0dot5rem'><FormattedMessage defaultMessage='Share'/></p>
                    </button>
                </div>
                <div className='flex-1'>
                    <button onClick={downloadQr} className='cursor-pointer background-color-gray-100 padding-top-0dot625rem padding-bottom-0dot625rem color-gray-700 display-flex border-radius-0dot5rem card-btn-shadow width-100percent justify-content-center'>
                        <DownloadIcon className='display-block width-1dot25rem'/>
                        <p className='text-sm medium padding-left-0dot5rem'>{i18n(translations.download, 'name', locale)}</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

const TicketPage = () => {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const {impulseId} = useParams();
    const {locale = 'uk'} = useQuery();
    const {impulseById} = useLazyLoadQuery(
        graphql`
            query TicketImpulseQuery($id: String) {
                impulseById(id: $id) {
                    id
                    amount
                    impulseService {
                        name
                        nameUk
                        demo
                        merchantAccount
                        service {
                            id
                            target
                            location
                            locationUk
                        }
                    }
                    impulseEndpoints {
                        name
                        nameUk
                        price
                        quantity
                        numbers
                        untie {
                            price
                            currency
                        }
                    }
                    impulseAddls {
                        name
                        nameUk
                        value
                    }
                    impulseClient {
                        email
                    }
                    orderReference
                    modified
                    status
                    qrs
                    version
                    number
                    merchant {
                        id
                    }
                }
            }
        `,
        {id: impulseId}
    );
    const qrsArray = [];
    impulseById.qrs.forEach((endpointQrs, endpointIndex) => {
        endpointQrs.forEach((qr, qrIndex) => {
            qrsArray.push({
                qr,
                qrIndex,
                endpointIndex
            })
        });
    });
    
    return (
        <div className='wallet-impulse'>
            <div className='impulse-background'>
                <div className='view'>
                    <div className='impulse-block mw768-position-relative'>
                        <div className='position-absolute top-1dot25rem mw768-top-2dot5rem right-1dot25rem mw768-padding-0dot5rem cursor-pointer'>
                            <Link to='../../'>
                                <XCloseIcon className='display-block height-1dot5rem color-white'/>
                            </Link>
                        </div>
                        <div className='text-align-center padding-top-4dot25rem mw768-padding-top-2dot5rem padding-left-1dot5rem padding-right-1dot5rem mw768-padding-left-3rem mw768-padding-right-3rem'>
                            <p className='color-white text-xl semibold'>{i18n(impulseById.impulseService, 'name', locale)}</p>
                            {(impulseById.impulseService.service.target || impulseById.impulseService.service.location) &&
                                <div className='color-primary-25 text-md padding-top-0dot25rem mw768-padding-top-0dot75rem'>
                                   {impulseById.impulseService.service.target && <span>{moment(impulseById.impulseService.service.target).format('DD.MM.YYYY')}</span>}
                                   {impulseById.impulseService.service.target && impulseById.impulseService.service.location && <span>, </span>}
                                   {impulseById.impulseService.service.location && <span>{i18n(impulseById.impulseService.service, 'location', locale)}</span>}
                                </div>
                            }
                        </div>
                        <div className='mw768-display-flex'>
                            <div className={classNames('display-none align-self-center', {'mw768-display-block': qrsArray.length > 1})}>
                                <button className='cursor-pointer padding-0dot5rem background-color-primary-900 color-primary-100 border-radius-1dot125rem' onClick={() => setActiveIndex(activeIndex > 0 ? activeIndex - 1 : 0)}>
                                    <ChevronLeft className='display-block height-1dot25rem'/>
                                </button>
                            </div>
                            <div className='mw768-flex-1'>
                                <div className='position-relative display-flex qr-cards padding-left-1rem padding-right-1rem padding-bottom-2rem padding-top-2dot375rem mw768-padding-top-6dot75rem'>
                                    {qrsArray.map((item, index) => (
                                        <div key={index} className={classNames('qr-card-container', {'qr-card-container--visible': activeIndex === index})} data-snap-index={index}>
                                            <QrCard {...{qr: item.qr, index, impulse: impulseById, qrsAmount: qrsArray.length, endpointIndex: item.endpointIndex, qrIndex: item.qrIndex}}/>
                                        </div>
                                    ))}
                                </div>
                                <div className={classNames('display-none position-relative qrs-pagination padding-0dot5rem background-color-primary-900 border-radius-0dot75rem width-fit-content margin-0-auto', {'mw768-display-flex': qrsArray.length > 1})}>
                                    {qrsArray.map((item, index) => (
                                        <div key={index} className={classNames('display-none width-0dot5rem height-0dot5rem border-radius-0dot25rem', {'background-color-primary-700': activeIndex !== index,'background-color-primary-100': activeIndex === index, 'display-block-inline': qrsArray.length < 4, 'qrs-pagination__active-first': activeIndex === 0 && index === activeIndex && qrsArray.length > 3, 'qrs-pagination__active-last': activeIndex === qrsArray.length - 1 && index === activeIndex && qrsArray.length > 3, 'qrs-pagination__active-middle': activeIndex !== qrsArray.length - 1 && index === activeIndex && activeIndex !== 0 && qrsArray.length > 3})}>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={classNames('display-none align-self-center', {'mw768-display-block': qrsArray.length > 1})}>
                                <button className='cursor-pointer padding-0dot5rem background-color-primary-900 color-primary-100 border-radius-1dot125rem' onClick={() => setActiveIndex(activeIndex < qrsArray.length - 1 ? activeIndex + 1 : 0)}> 
                                    <ChevronRight className='display-block height-1dot25rem'/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(TicketPage);