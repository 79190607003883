import React from 'react';
import {Link, useResolvedPath, useMatch} from 'react-router-dom';

export default React.memo(({children, to, end = true, ...props}) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({path: resolved.pathname, end});
    return (
      <>
        <Link to={to} {...props}>
          {children(match)}
        </Link>
      </>
    );
});

