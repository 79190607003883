import React from 'react'
import {Link, useLocation} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../components/Skeleton';
import {useCartDispatch, useCartState} from '../../contexts/cart';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import searchFilter from '../../searchFilter';
import {Helmet} from 'react-helmet-async';
import Marked from '../../components/Marked';
import i18n from '../../i18n';
import useQuery from '../../useQuery';
import {FormattedMessage} from 'react-intl';
import useNow from '../../useNow';
import {adopt} from '../../math';
import {IN, OUT_NEGATIVE, TICKETS_CONTACT_FORM, SUPPORT_CONTACT, CONTACT_EMAIL, ENTITY_TYPE, OUT_POSITIVE, COUNTDOWN_CONSTRAINTS} from '../../constants';
import IconMaster from '../../assets/icons-payment/icon-master.svg';
import IconVisa from '../../assets/icons-payment/icon-visa.svg';
import IconApplePay from '../../assets/icons-payment/icon-apple-pay.svg';
import IconGooglePay from '../../assets/icons-payment/icon-google-pay.svg';
import {useForm} from 'react-hook-form';
import ErrorFallback from '../../components/ErrorFallback';
import Browser from '../../components/Browser';
import Ogle from '../../components/theme/Ogle';
import LogoWhiteDarkModeTrue from '../../assets/logo-white-dark-mode-true.svg';
import ButtonIcon from '../../components/button-icon/button-icon.component';
import CartMinusCircle from '../../assets/icons/cart-minus-circle.svg';
import CartAddCircle from '../../assets/icons/cart-add-circle.svg';
import EndpointsHeader, {WarpEndpointsHeader} from './endpoints-header';
import moment from 'moment';
import ServiceSchedule, {SERVICE_DESCRIPTION, ServiceScheduleCard} from './service-schedule';
import {isSet, isTrue} from '../../utility';
import EndpointSchedule from './endpoint-schedule';
import './e.scss';
import classNames from 'classnames';
import {format} from 'date-fns';
import {getServiceTagValue} from '../../utilities/get-tag-value';


const legal = 'https://www.ottry.com/legal';
const year = new Date().getFullYear();

const config = {shouldValidate: true, shouldDirty: true};

const Endpoint = React.memo(({endpoint, serviceById}) => {
    const {locale = 'uk', more} = useQuery();
    const state = useCartState();
    const dispatch = useCartDispatch();
    const [isAnimationRunning, setIsAnimationRunning] = React.useState(false);
    const fEndpoint = useFragment(
        graphql`
            fragment eEndpoint_endpoint on Endpoint {
                id
                name
                nameUk
                quantity
                price
                start
                end
                strategy
                every
                description
                descriptionUk
                untie
                hints
                tags
            }
        `,
        endpoint
    );
    const fServiceById = useFragment(
        graphql`
            fragment eEndpoint_serviceById on Service {
                id
                nameUk
                name
                target
            }
        `,
        serviceById
    );
    const cartEntry = React.useMemo(() =>
            state.find(e => e.serviceId === fServiceById.id && e.endpointId === fEndpoint.id),
        [state, fEndpoint.id, fServiceById.id]
    );
    const now = useNow();
    const dpt = React.useMemo(() => adopt(now, fEndpoint.start, fEndpoint.end, fEndpoint.strategy,
        fEndpoint.every), [now, fEndpoint]);
    const {register, handleSubmit, formState: {errors}, setValue} = useForm();
    const addEndpoint = () => {
        if(!cartEntry) {
            setIsAnimationRunning(true);
            dispatch({type: 'add', payload: {
                endpointId: fEndpoint.id,
                serviceId: fServiceById.id,
                ...(fEndpoint.untie && {untie: {price: data.input, currency: 'uah'}}) 
            }});
        }
    };
    const incrementEndpoint = () => {
        dispatch({type: 'increment', payload: cartEntry.key});
    };
    const decrementEndpoint = () => {
        if (cartEntry.quantity > 1) {
            dispatch({type: 'decrement', payload: cartEntry.key});
        } else {
            dispatch({type: 'remove', payload: cartEntry.key});
        }
    };
    const alternativePriceTag = fEndpoint.tags && fEndpoint.tags.length && fEndpoint.tags.find(tag => tag.includes('price'));
    return (
        <div className='border-radius-0dot75rem background-color-gray-900 border-1px-solid padding-1dot25rem mw768-padding-2rem border-color-gray-900'>
            <div className='display-flex flex-direction-column-row'>
                <div className=' mw768-flex-1 mw768-margin-right-1rem'>
                    <div className='color-gray-100 text-lg medium mw768-text-xl'>
                        {i18n(fServiceById, 'name', locale)} | {i18n(fEndpoint, 'name', locale)}
                    </div>
                    {fServiceById.target && moment(fServiceById.target).isValid() && <div className='color-gray-400 text-sm mw768-text-md padding-top-0dot5rem'>{moment(fServiceById.target).format('DD.MM.YYYY')}</div>}
                    {((dpt.state === IN && fEndpoint.quantity < 16) || isTrue(more)) && <div className='padding-top-0dot5rem color-warning-500 text-sm'><FormattedMessage defaultMessage='Left'/> {fEndpoint.quantity}</div>}
                    <EndpointSchedule {...{
                        start: fEndpoint.start,
                        end: fEndpoint.end,
                        strategy: fEndpoint.strategy,
                        every: fEndpoint.every
                    }}/>
                </div>
                {dpt.state === IN &&
                    <div className='display-flex mw768-flex-1 justify-content-space-between align-items-center mw768-align-self-center mw768-justify-content-flex-end padding-top-1rem mw768-padding-top-0'>
                        <p className='mw768-margin-right-2dot5rem'>{fEndpoint.price} UAH {alternativePriceTag ? `(${alternativePriceTag.replace('price=', '')})` : ''}</p>
                        <div className='width-7rem min-height-2dot625rem'>
                            <Browser>
                                <div className={classNames('border-1px-solid padding-0dot5rem border-radius-0dot5rem text-md medium display-flex position-relative justify-content-center', {'border-color-gray-700 cursor-pointer width-100percent buy-btn-animation--backwards': !cartEntry, 'border-color-gray-800 buy-btn-animation--forward': !!cartEntry, 'buy-btn-animation--play': isAnimationRunning})} onClick={() => addEndpoint()}>
                                    <span className={classNames('color-primary-500', {'add-to-cart--visible': !cartEntry, 'add-to-cart--hidden': !!cartEntry})}><FormattedMessage defaultMessage='Buy'/></span>
                                    <div className={classNames({'decrement-endpoint--visible': !!cartEntry, 'decrement-endpoint--hidden': !cartEntry})}>
                                        <ButtonIcon {...{clickHandler: () => decrementEndpoint()}}>
                                            <CartMinusCircle className='width-100percent color-primary-500'/>
                                        </ButtonIcon>
                                    </div>
                                    <p className={classNames('color-gray-100 text-md text-align-center', {'endpoint-quantity--visible': !!cartEntry, 'endpoint-quantity--hidden': !cartEntry})}>{cartEntry ? cartEntry.quantity : ''}</p>
                                    <div className={classNames({'increment-endpoint--visible': !!cartEntry, 'increment-endpoint--hidden': !cartEntry})}>
                                        <ButtonIcon {...{clickHandler: () => incrementEndpoint()}}>
                                            <CartAddCircle className='width-100percent color-primary-500'/>
                                        </ButtonIcon>
                                    </div>
                                </div>
                            </Browser>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
});

const EndpointWithScheduleContainer = ({endpoint, children, now}) => {
    const {more} = useQuery();
    const [showEndpoint, setShowEndpoint] = React.useState(endpoint.quantity > 0 || more);
    const {state} = React.useMemo(() => adopt(now, endpoint.start, endpoint.end, endpoint.strategy,
        endpoint.every), [now, endpoint]);
    React.useEffect(() => {
        if (state === OUT_NEGATIVE && isSet(endpoint.end) && moment(endpoint.end).add(COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].amount, COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].units) < moment(now)) {
            setShowEndpoint(more || false);
        } else if (state === OUT_POSITIVE && isSet(endpoint.start) && moment(now) < moment(endpoint.start).subtract(COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].amount, COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].units)) {
            setShowEndpoint(more || false);
        } else {
            setShowEndpoint(endpoint.quantity > 0 || more);
        }
    }, [endpoint, state, now]);
    return (
        <>
            {showEndpoint &&
                <div className='padding-bottom-1dot75rem'>
                    {children}
                </div>
            }
        </>
    )
}

const EndpointContainer = ({endpoint, children}) => {
    const {more} = useQuery();
    if ((!endpoint.start && !endpoint.end && endpoint.quantity > 0) || more) return (
        <div className='padding-bottom-1dot75rem'>
            {children}
        </div>
    )
    const now = useNow();
    return (
        <EndpointWithScheduleContainer {...{endpoint, now}}>
            {children}
        </EndpointWithScheduleContainer>
    );

}

const Service = ({id, setShowTicketsBoxBtn, ticketsBoxRef}) => {
    const data = useLazyLoadQuery(
        graphql`
            query eQuery($id: String) {
                serviceById(id: $id) {
                    id
                    name
                    nameUk
                    details
                    detailsUk
                    description
                    start
                    end
                    strategy
                    every
                    target
                    endpoints {
                        id
                        quantity
                        start
                        end
                        strategy
                        every
                        price
                        ...eEndpoint_endpoint
                    }
                    ...eEndpoint_serviceById
                }
            }
        `,
        {id}
    );
    React.useEffect(() => {
        setShowTicketsBoxBtn(data.serviceById.description && data.serviceById.description.includes('btnAnchorTickets'));
    }, [setShowTicketsBoxBtn, data]);
    const {locale = 'uk'} = useQuery();
    const cover = React.useMemo(() => {
        if (i18n(data.serviceById, 'details', locale)) {
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
            const found = i18n(data.serviceById, 'details', locale).match(regex);
            return found && found[0];
        }
    }, [data, locale]);
    const now = useNow();
    const {state} = React.useMemo(() => adopt(now, data.serviceById.start, data.serviceById.end, data.serviceById.strategy,
        data.serviceById.every), [now, data.serviceById]);
    const target = data.serviceById.target ? format(new Date(data.serviceById.target), 'dd-MM-yyyy') : null;
    const lowestTicketPrice = React.useMemo(() => {
        data.serviceById.endpoints ? data.serviceById.endpoints.map(e => e.price).sort() : [];
        if (data.serviceById.endpoints && data.serviceById.endpoints.length) {
            return data.serviceById.endpoints.reduce((price, endpoint) => {
                price = price > endpoint.price ? endpoint.price : price;
                return price;
            }, data.serviceById.endpoints[0].price);
        }
    }, [data]);
    const seoDescription = decodeURI(getServiceTagValue(data.serviceById.description, 'seoDescription', {defaultValue: ''}));
    const seoTitle = decodeURI(getServiceTagValue(data.serviceById.description, 'seoTitle', {defaultValue: ''}));
    const defaultDescription = `Придбати квиток на ⭐️ ${i18n(data.serviceById, 'name', locale)}${target ? ` ☝${target}` : ''}${lowestTicketPrice ? `. Від ${lowestTicketPrice} грн` : ''} | Купити квитки онлайн`;
    return (
        <>
        <Helmet>
            <title>{seoTitle || `${i18n(data.serviceById, 'name', locale)}: Купити квитки на ottry.com`}</title>
            <meta name='description' content={seoDescription || defaultDescription}/>
            <meta property='og:image' content={cover}/>
        </Helmet>
        <div>
            <div className='view'>
                <div className='block'>
                    <div className='max-width-50rem margin-0-auto mw768-padding-top-4rem color-gray-100'>
                        <h1 className='display-sm semibold color-gray-100 text-align-center margin-bottom-1rem'>
                            {i18n(data.serviceById, 'name', locale)}
                        </h1>
                        <Marked {...{content: i18n(data.serviceById, 'details', locale)}}/>
                        <div className='margin-top-1rem mw768-margin-top-3rem padding-top-2rem padding-bottom-2rem' ref={ticketsBoxRef}>
                            <WarpEndpointsHeader {...{serviceById: data.serviceById, mode: 'dark'}}>
                                {({decrementEndpoint, incrementEndpoint, localCartState, checkout}) =>
                                    <EndpointsHeader {...{serviceById: data.serviceById, decrementEndpoint, incrementEndpoint, localCartState, checkout, showCart: state !== OUT_NEGATIVE}}/>
                                }
                            </WarpEndpointsHeader>
                            <div className='padding-top-2rem'>
                                <div>
                                    {i18n(data.serviceById, 'details', locale) && i18n(data.serviceById, 'details', locale).endsWith('!') ?
                                        <div className='padding-bottom-2rem'>
                                            <ServiceScheduleCard {...SERVICE_DESCRIPTION[OUT_NEGATIVE].default}/>
                                        </div>
                                        :
                                        <>
                                            <ServiceSchedule {...{
                                                start: data.serviceById.start,
                                                end: data.serviceById.end,
                                                strategy: data.serviceById.strategy,
                                                every: data.serviceById.every,
                                                state
                                            }}/>
                                            {state === IN &&
                                                <div>
                                                    {data.serviceById.endpoints.map((endpoint) =>
                                                        <EndpointContainer key={endpoint.id} {...{endpoint}}>
                                                            <Endpoint {...{endpoint, serviceById: data.serviceById}}/>
                                                        </EndpointContainer>
                                                    )}
                                                    {!data.serviceById.endpoints.some(e => e.quantity) && <ServiceScheduleCard {...SERVICE_DESCRIPTION[OUT_NEGATIVE].default}/>}
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            {state === IN && data.serviceById.endpoints.some(e => e.quantity) && <p className='text-dm mw768-text-md color-gray-400 padding-top-0dot25rem'><FormattedMessage defaultMessage='* After payment, a letter with a QR code will be sent to your e-mail address, which must be presented at the entrance. Please note that in the case of using the Gmail mail service, the letter with the ticket may end up in the "Alerts", "Promotions" or "Social networks" folder.'/></p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};

const E = ({id}) => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    const [showTicketsBoxBtn, setShowTicketsBoxBtn] = React.useState(false);
    const ticketsBoxRef = React.useRef();
    const scrollToTickets = () => {
        ticketsBoxRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }
    return (
        <Ogle>
            <div className='pages'>
                <div className='warp'>
                    <div className='e'>
                        <div className='view'>
                            <div className='block'>
                                <div className='padding-top-1rem padding-bottom-1rem mw768-padding-top-1dot25rem mw768-padding-bottom-1dot25rem display-flex justify-content-space-between align-items-center'>
                                    <Link to={`/events${searchFilter(location.search)}`}>
                                        <LogoWhiteDarkModeTrue className='height-2rem'/>
                                    </Link>
                                    {showTicketsBoxBtn &&
                                        <div className='border-radius-0dot5rem border-1px-solid border-color-gray-300 box-shadow-xs text-sm medium color-white padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot875rem padding-right-0dot875rem cursor-pointer' onClick={() => scrollToTickets()}>
                                            <FormattedMessage defaultMessage='Tickets'/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <React.Suspense fallback={
                                <div className='view'>
                                    <div className='block'>
                                        <Skeleton/>
                                    </div>
                                </div>
                            }>
                                <Service {...{id, setShowTicketsBoxBtn, ticketsBoxRef}}/>
                            </React.Suspense>
                        </ErrorBoundary>
                        <div className='view'>
                            <div className='block'>
                                <div className='warp-footer margin-top-4rem border-top-1px-solid border-color-gray-600 padding-top-2rem mw768-padding-top-3rem padding-bottom-2rem mw768-padding-bottom-3rem'>
                                    <Link to={`/events${searchFilter(location.search)}`}>
                                        <LogoWhiteDarkModeTrue className='height-2rem'/>
                                    </Link>
                                    <div className='display-flex flex-direction-column-row padding-top-1dot5rem mw768-padding-top-2rem'>
                                        <div className='flex-1'>
                                            <div className='margin-bottom-0dot5rem'>
                                                <a className='text-md medium color-gray-200' href={`tel:${SUPPORT_CONTACT.cell}`} data-gtm='warp support-phone'>
                                                    {SUPPORT_CONTACT.cellFormatted}
                                                </a>
                                            </div>
                                            <div>
                                                <a className='text-md medium color-primary-500' href={`mailto:${CONTACT_EMAIL}?subject=Warp support`} data-gtm='warp email'>{CONTACT_EMAIL}</a>
                                            </div>
                                        </div>
                                        <div className='mw768-align-self-flex-end display-flex padding-top-2rem mw768-padding-top-0'>
                                            <IconMaster className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                            <IconVisa className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                            <IconApplePay className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                            <IconGooglePay className='display-block height-2rem mw768-height-2dot5rem'/>
                                        </div>
                                    </div>
                                    <div className='display-flex flex-direction-column-row padding-top-1dot5rem mw768-padding-top-2rem margin-top-2rem mw768-margin-top-4rem border-top-1px-solid border-color-gray-600'>
                                        <div className='flex-order-2 mw768-flex-order-1 flex-1 padding-top-1rem mw768-padding-top-0'>
                                            <p className='text-md color-gray-200'>© {year} Ottry. All rights reserved.</p>
                                        </div>
                                        <div className='flex-order-1 mw768-flex-order-2 display-flex flex-wrap-wrap gap-0dot5rem-1rem'>
                                            <Link className='text-md color-gray-200' to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                                            <Link className='text-md color-gray-200' to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                                            <Link className='text-md color-gray-200' to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                                            <a className='text-md color-gray-200' href={TICKETS_CONTACT_FORM} target='_blank'><FormattedMessage defaultMessage='I want to sell online'/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Ogle>
    );
};

export default React.memo(E);