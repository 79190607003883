import React from 'react';
import './fcfc.scss';
import {Link, useLocation, useParams} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../../components/Skeleton';
import {useNavigate} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import searchFilter from '../../../searchFilter';
import {Helmet} from 'react-helmet-async';
import Marked from '../../../components/Marked';
import i18n from '../../../i18n';
import useQuery from '../../../useQuery';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import useNow from '../../../useNow';
import {adopt} from '../../../math';
import {IN, ENTITY_TYPE, SUPPORT_CONTACT, CONTACT_EMAIL, TICKETS_CONTACT_FORM} from '../../../constants';
import Mini from '../../../components/root/mini/Mini';
import IconMaster from '../../../assets/icons-payment/icon-master.svg';
import IconVisa from '../../../assets/icons-payment/icon-visa.svg';
import IconApplePay from '../../../assets/icons-payment/icon-apple-pay.svg';
import IconGooglePay from '../../../assets/icons-payment/icon-google-pay.svg';
import ErrorFallback from '../../../components/ErrorFallback';
import Browser from '../../../components/Browser';
import Ticket01 from '../../../assets/icons0/ticket-01.svg';
import ShoppingBag03 from '../../../assets/icons0/shopping-bag-03.svg';
import SeatMap from './seat-map';
import LogoWhiteDarkModeTrue from '../../../assets/logo-white-dark-mode-true.svg'
import {useInstantDispatch} from '../../../contexts/instant';
import ChevronUp from '../../../assets/icons0/chevron-up.svg';
import splitPrice from '../../checkout/splitPrice';
import moment from 'moment';
import CartAddCircle from '../../../assets/icons/cart-add-circle.svg';
import CartMinusCircle from '../../../assets/icons/cart-minus-circle.svg';
import XClose from '../../../assets/icons0/x-close.svg';
import SafetyMark from '../../../assets/icons/safety-mark.svg';
import Button from '../../../components/button/button';
import ButtonIcon from '../../../components/button-icon/button-icon.component';
import {getServiceTagValue} from '../../../utilities/get-tag-value';
import {format} from 'date-fns';
import ServiceNotFound from '../../warp/service-not-found';

const legal = 'https://www.ottry.com/legal';
const year = new Date().getFullYear();

const OrderItem = ({endpointId, serviceById, removeEndpointFromCart}) => {
    const {locale = 'uk'} = useQuery();
    const {endpointById} = useLazyLoadQuery(
        graphql`
            query fcfcOrderItemQuery($endpointId: String) {
                endpointById(id: $endpointId) {
                    id
                    name
                    price
                    nameUk
                    quantity
                }
            }
        `,
        {endpointId: endpointId}
    );
    const service = useFragment(
        graphql`
            fragment fcfcOrderItemService_serviceByIdOrSlug on Service {
                id
                name
                target
                nameUk
            }
        `,
        serviceById
    );    
    return (
        <div className='order-item'>
            <p className='text-md color-gray-700 mw768-text-lg'>{i18n(service, 'name', locale)} | <span className='white-space-nowrap'>{i18n(endpointById, 'name', locale)}</span></p>
            {moment(service.target).isValid() && <p className='text-sm color-gray-500 padding-top-0dot25rem'>{moment(service.target).format('DD.MM.YYYY')}</p>}
            <div className='order-item__footer'>
                <div className='order-item__actions'>
                    <div className='order-item__actions-group'>
                        <ButtonIcon {...{color: 'primary', clickHandler: () => removeEndpointFromCart(endpointId)}}>
                            <CartMinusCircle className='order-item__actions-svg'/>
                        </ButtonIcon>
                        <p className='order-item__amount color-primary-600 text-md medium'>1</p>
                        <ButtonIcon {...{color: 'primary', disabled: true}}>
                            <CartAddCircle className='order-item__actions-svg'/>
                        </ButtonIcon>
                    </div>
                </div>
                <p className='text-md color-gray-900 medium mw768-text-lg'>
                    {endpointById.price.toFixed(2)} <FormattedMessage defaultMessage='UAH' />
                </p>
            </div>
        </div>
    )
};

const CartFooterDesktopContent = ({localCartState, deferred, serviceById, checkout}) => {
    return (
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <div className='cart-footer__details cart-footer__details--visible'>
                <div className='display-flex align-items-center'>
                    <div className='text-sm color-gray-500 flex-1'>
                        <span><FormattedMessage defaultMessage='Services fee'/></span>
                    </div>
                    <React.Suspense fallback={
                        <div className='text-md medium color-gray-700 opacity-0dot4 pointer-events-none'>
                            <span>0.<span className='text-xs'>00 </span></span>
                            <FormattedMessage defaultMessage='UAH'/>
                        </div>
                    }>
                        <ServiceFee{...{localCartState, serviceId: serviceById.id, deferred}}/>
                    </React.Suspense>
                </div>
            </div>
            <div className='display-flex flex-direction-row justify-content-space-between align-items-center flex-1 padding-bottom-0dot75rem'>
                <div className='text-sm color-gray-700'>
                    <FormattedMessage defaultMessage='Total'/>
                </div>
                <React.Suspense fallback={<span className='text-lg semibold color-gray-900 opacity-0dot4'>0.00 <FormattedMessage defaultMessage='UAH'/></span>}>
                    <Amount {...{localCartState, serviceId: serviceById.id, deferred, classes: 'text-lg semibold color-gray-900'}}/>
                </React.Suspense>
            </div>
            <div className='cart-footer__payment padding-bottom-0dot75rem'>
                <Button {...{size: 'xl', color: 'primary-gradient', fluid: 'always', clickHandler: () => checkout()}}>
                    <FormattedMessage defaultMessage='Checkout'/>
                </Button>
            </div>
            <div className='padding-top-1rem padding-bottom-1rem display-flex justify-content-center'>
                <SafetyMark className='display-block margin-right-0dot75rem'/>
                <p className='text-md color-gray-900'><FormattedMessage defaultMessage='100% Safe and Secure'/></p>
            </div>
        </ErrorBoundary>
    )
}

const Cart = ({removeEndpointFromCart, serviceById, localCartState, cartVisible, closeCart}) => {
    const localCartStateDeferred = React.useDeferredValue(localCartState);
    return (
        <div className={classNames('pop-up-cart-backdrop', {'show': cartVisible})}>
            <div className={classNames('pop-up-cart position-fixed background-color-white bottom-0 right-0', {'pop-up-cart--visible': cartVisible})}>
                <div className='position-sticky top-0 background-color-white'>
                    <div className='display-flex justify-content-flex-end padding-1dot25rem-1rem mw768-padding-1dot25rem-2dot5rem'>
                        <XClose className='display-block height-1dot5rem width-1dot5rem color-gray-500 cursor-pointer' onClick={closeCart}/>
                    </div>
                    <h3 className='display-sm semibold color-gray-900 padding-left-1dot25rem padding-right-1dot25rem mw768-padding-left-2dot5rem mw768-padding-right-2dot5rem padding-bottom-2rem'>
                        <FormattedMessage defaultMessage='Cart'/>
                    </h3>
                </div>
                <div className='pop-up-cart__content padding-left-1dot25rem padding-right-1dot25rem mw768-padding-left-2dot5rem mw768-padding-right-2dot5rem'>
                    {localCartState.map(endpointId =>
                        <React.Suspense key={endpointId} fallback={<Skeleton/>}>
                            <OrderItem {...{endpointId, serviceById, removeEndpointFromCart}}/>
                        </React.Suspense>
                    )}
                </div>
                <div className='hide-small'>
                    <CartFooter {...{localCartState, serviceById}}>
                        {checkout =>
                            <CartFooterDesktopContent {...{localCartState: localCartStateDeferred, serviceById, deferred: localCartStateDeferred !== localCartState, checkout}}/>
                        }
                    </CartFooter>
                </div>
            </div>
        </div>
    )
}

const Amount = ({localCartState, serviceId, deferred, classes}) => {
    const request = React.useMemo(() => ({
        picks: localCartState.map((e) => ({endpoint: e, service: serviceId, quantity: 1}))
    }), [localCartState]);
    const {landImpulse} = useLazyLoadQuery(
        graphql`
            query fcfcAmountLandImpulseQuery($request: LandRequestInput) {
                landImpulse(request: $request) {
                    lands {
                        pickExs {
                            index
                        }
                    }
                    solves {
                        amount
                    }
                }
            }
        `,
        {request}
    );
    return (
        <span className={classNames(classes, {'opacity-0dot4 pointer-events-none' : deferred})}>
            {landImpulse && landImpulse.solves[0] ? landImpulse.solves[0].amount : '0.00'} <FormattedMessage defaultMessage='UAH'/>
        </span>
    )
}

const ServiceFee = ({deferred, localCartState, serviceId}) => {
    const request = React.useMemo(() => ({
        picks: localCartState.map((e) => ({endpoint: e, service: serviceId, quantity: 1}))
    }), [localCartState]);
    const {landImpulse} = useLazyLoadQuery(
        graphql`
            query fcfcServiceFeeLandImpulseQuery($request: LandRequestInput) {
                landImpulse(request: $request) {
                    lands {
                        pickExs {
                            index
                        }
                    }
                    solves {
                        serviceFeeAmount
                    }
                }
            }
        `,
        {request}
    );
    const serviceFee = React.useMemo(() => landImpulse.solves[0] ? splitPrice(landImpulse.solves[0].serviceFeeAmount) : {whole: '0', fraction: '00'}, [landImpulse]);
    return (
        <div className={classNames('text-sm mw768-text-md medium color-gray-700', {'opacity-0dot4 pointer-events-none': deferred})}>
            <span><span>{serviceFee.whole}.</span><span className='text-xs'>{serviceFee.fraction} </span></span>
            <FormattedMessage defaultMessage='UAH'/>
        </div>
    )
}

const CartFooterMobileContent = ({flag, localCartState, serviceById, deferred, setFlag, checkout}) => {
    return (
        <>
            <div className={classNames('cart-footer__details', {'cart-footer__details--visible': flag})}>
                <div className='display-flex'>
                    <div className='text-sm color-gray-500 flex-1'>
                        <span><FormattedMessage defaultMessage='Services fee'/></span>
                    </div>
                    <React.Suspense fallback={
                        <div className='text-md medium color-gray-700 opacity-0dot4 pointer-events-none'>
                            <span>0.<span className='text-xs'>00 </span></span>
                            <FormattedMessage defaultMessage='UAH'/>
                        </div>
                    }>
                        <ServiceFee{...{localCartState, serviceId: serviceById.id, deferred}}/>
                    </React.Suspense>
                </div>   
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <div className='cart-footer__payment display-flex align-items-center flex-direction-row-column'>
                    <div className='cart-footer__action flex-1 display-flex' onClick={() => setFlag(!flag)}>
                        <div className='padding-top-0dot1875rem'>
                            <ChevronUp className={classNames('cart-footer__indicator color-primary-500 height-1dot25rem width-1dot25rem display-block', {'cart-footer__indicator--active': flag})}/>
                            <ShoppingBag03 className='color-primary-500 height-1dot25rem width-1dot25rem display-block'/>
                        </div>
                        <div className='cart-footer__total justify-content-space-between flex-1'>
                            <div className='text-sm color-gray-700'>
                                <FormattedMessage defaultMessage='Total'/>
                            </div>
                            <React.Suspense fallback={<span className='text-lg semibold color-gray-900 opacity-0dot4'>0.00 <FormattedMessage defaultMessage='UAH'/></span>}>
                                <Amount {...{localCartState, serviceId: serviceById.id, deferred, classes: 'text-lg semibold color-gray-900'}}/>
                            </React.Suspense>
                        </div>
                    </div>
                    <Button {...{size: '2xl', color: 'primary-gradient', clickHandler: () => checkout()}}>
                        <FormattedMessage defaultMessage='Checkout'/>
                    </Button>
                </div>
            </ErrorBoundary>
        </>
    )
}

const CartFooter = ({localCartState, serviceById, children, hide}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useInstantDispatch();
    const [isPending, startTransition] = React.useTransition();
    const checkout = React.useCallback(() => {
        startTransition(() => {
            dispatch({type: 'reset', payload: []});
            localCartState.forEach(endpointId => {
                dispatch({type: 'add-several', payload: {
                    endpointId: endpointId,
                    serviceId: serviceById.id
                }});
            })
            navigate(`/checkout-new/ticketing-instant/impulse${searchFilter(location.search)}`);
        })
    }, [localCartState]);
    return (
        <div className='cart-footer position-fixed bottom-0 left-0 width-100percent z-index-1'>
            {children(checkout)}
        </div>
    )
}

const CartButton = ({serviceById, localCartState, removeEndpointFromCart}) => {
    const localCartStateDeferred = React.useDeferredValue(localCartState);
    const [cartVisibility, setCartVisibility] = React.useState(false);
    const setCartVisibilityCallback = React.useCallback((value) => setCartVisibility(value));
    React.useEffect(() => {
        if (!localCartState.length) {
            setCartVisibility(false);
        }
    }, [localCartState])
    return (
        <>
            <div className='display-none mw768-display-block min-width-11rem'>
                <Button {...{size: 'lg', color: 'primary-gradient', fluid: 'always', disabled: !localCartState.length, clickHandler: () => setCartVisibility(true)}}>
                    {localCartState.length ?
                        <React.Suspense fallback={
                            <>
                                <ShoppingBag03 className='display-block height-1dot25rem margin-right-0dot5rem color-white opacity-0dot4'/>
                                <span className='opacity-0dot4'>0.00 <FormattedMessage defaultMessage='UAH'/></span>
                            </>
                        }>
                            <ShoppingBag03 className={classNames('display-block color-white height-1dot25rem margin-right-0dot5rem', {'opacity-0dot4': localCartStateDeferred !== localCartState})}/>
                            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                                <Amount {...{localCartState: localCartStateDeferred, serviceId: serviceById.id, deferred: localCartStateDeferred !== localCartState}}/>
                            </ErrorBoundary>
                        </React.Suspense>
                        :
                        <>
                            <ShoppingBag03 className='display-block height-1dot25rem margin-right-0dot5rem'/>
                            <span>0.00 <FormattedMessage defaultMessage='UAH'/></span>
                        </>
                    }
                </Button>
            </div>
            {!!localCartState.length && 
                <>
                    <div className='mw768-display-none'>
                        <CartFooter {...{localCartState, serviceById}}>
                            {checkout =>
                                <CartFooterMobileContent {...{localCartState: localCartStateDeferred, serviceById, deferred: localCartStateDeferred !== localCartState, checkout, flag: cartVisibility, setFlag: setCartVisibilityCallback}}/>
                            }
                        </CartFooter>
                    </div>
                    <Cart {...{cartVisible: cartVisibility, removeEndpointFromCart, serviceById, localCartState, deferred: localCartStateDeferred !== localCartState, closeCart: () => setCartVisibilityCallback(false)}}/>
                </>
            }
        </>
    )
}

const FcfcTickets = ({serviceById}) => {
    const [localCartState, setLocalCartState] = React.useState([]);
    const service = useFragment(
        graphql`
            fragment fcfcTickets_serviceByIdOrSlug on Service {
                id
                description
                endpoints {
                    id
                    price
                    tags
                    quantity
                }
                
            }
        `,
        serviceById
    );

    const toggleEndpoint = (endpointId) => {
        if (localCartState.includes(endpointId)) {
            setLocalCartState(localCartState.filter(e => e !== endpointId));
        } else {
            const newLocalState = localCartState.slice();
            newLocalState.push(endpointId);
            setLocalCartState(newLocalState);
        }
    };
    const removeEndpointFromCart = React.useCallback((endpointId) => {
        setLocalCartState(localCartState.filter(e => e !== endpointId));
    })
    return (
        <>
            <div className='padding-top-2rem padding-bottom-1rem border-bottom-1px-solid border-color-gray-700 display-flex justify-content-space-between align-items-center background-color-black position-sticky top-0 z-index-1'>
                <div className='display-flex'>
                    <Ticket01 className='display-block height-1dot5rem color-primary-500'/>
                    <p className='margin-left-0dot75rem text-lg medium'><FormattedMessage defaultMessage='Tickets' /></p>
                </div>
                <div>
                    <Browser>
                        <CartButton {...{serviceById, localCartState, removeEndpointFromCart}}/>
                    </Browser>
                </div>
            </div>
            <div className='padding-top-2rem'>
                <SeatMap {...{service, toggleEndpoint, localCartState}}/>
            </div>
        </>
    )
}

const Fcfc = ({setShowTicketsBoxBtn, ticketsBoxRef}) => {
    const {idOrSlug} = useParams();
    const data = useLazyLoadQuery(
        graphql`
            query fcfcQuery($payload: String) {
                serviceByIdOrSlug(payload: $payload) {
                    id
                    name
                    nameUk
                    details
                    detailsUk
                    description
                    start
                    end
                    strategy
                    every
                    target
                    endpoints {
                        id
                        quantity
                        price
                    }
                    ...fcfcTickets_serviceByIdOrSlug
                    ...fcfcOrderItemService_serviceByIdOrSlug
                }
            }
        `,
        {payload: idOrSlug}
    );
    React.useEffect(() => {
        setShowTicketsBoxBtn(data.serviceByIdOrSlug.description && data.serviceByIdOrSlug.description.includes('btnAnchorTickets'));
    }, [setShowTicketsBoxBtn, data]);
    const {locale = 'uk', more} = useQuery();
    const cover = React.useMemo(() => {
        if (i18n(data.serviceByIdOrSlug, 'details', locale)) {
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
            const found = i18n(data.serviceByIdOrSlug, 'details', locale).match(regex);
            return found && found[0];
        }
    }, [data, locale]);
    const now = useNow();
    const {state} = React.useMemo(() => adopt(now, data.serviceByIdOrSlug.start, data.serviceByIdOrSlug.end, data.serviceByIdOrSlug.strategy,
        data.serviceByIdOrSlug.every), [now, data.serviceByIdOrSlug]);
    const target = data.serviceByIdOrSlug.target ? format(new Date(data.serviceByIdOrSlug.target), 'dd-MM-yyyy') : null;
    const lowestTicketPrice = React.useMemo(() => {
        data.serviceByIdOrSlug.endpoints ? data.serviceByIdOrSlug.endpoints.map(e => e.price).sort() : [];
        if (data.serviceByIdOrSlug.endpoints && data.serviceByIdOrSlug.endpoints.length) {
            return data.serviceByIdOrSlug.endpoints.reduce((price, endpoint) => {
                price = price > endpoint.price ? endpoint.price : price;
                return price;
            }, data.serviceByIdOrSlug.endpoints[0].price);
        }
    }, [data]);
    const seoDescription = decodeURI(getServiceTagValue(data.serviceByIdOrSlug.description, 'seoDescription', {defaultValue: ''}));
    const seoTitle = decodeURI(getServiceTagValue(data.serviceByIdOrSlug.description, 'seoTitle', {defaultValue: ''}));
    const defaultDescription = `Придбати квиток на ⭐️ ${i18n(data.serviceByIdOrSlug, 'name', locale)}${target ? ` ☝${target}` : ''}${lowestTicketPrice ? `. Від ${lowestTicketPrice} грн` : ''} | Купити квитки онлайн`;
    return (
        <>
        <Helmet>
            <title>{seoTitle || `${i18n(data.serviceByIdOrSlug, 'name', locale)}: Купити квитки на ottry.com`}</title>
            <meta name='description' content={seoDescription || defaultDescription}/>
            <meta property='og:image' content={cover}/>
        </Helmet>
        <div>
            <div className='view'>
                <div className='block content'>
                    <h1 className='font-size-2rem font-weight-bold margin-bottom-1rem color-gray-100'>
                        {i18n(data.serviceByIdOrSlug, 'name', locale)}
                    </h1>
                    <Marked {...{content: i18n(data.serviceByIdOrSlug, 'details', locale)}}/>
                    <div className="display-flex flex-wrap-wrap">
                        <div className="flex-grow-1 flex-basis-300px margin-bottom-1rem">
                            {i18n(data.serviceByIdOrSlug, 'details', locale) && i18n(data.serviceByIdOrSlug, 'details', locale).endsWith('!') ?
                                <>
                                    <p><FormattedMessage defaultMessage='Sales were closed'/></p>
                                </>
                                :
                                <>
                                    <Mini {...{
                                        start: data.serviceByIdOrSlug.start,
                                        end: data.serviceByIdOrSlug.end,
                                        strategy: data.serviceByIdOrSlug.strategy,
                                        every: data.serviceByIdOrSlug.every,
                                        entityType: ENTITY_TYPE.service
                                    }}/>
                                    {state === IN &&
                                        <div ref={ticketsBoxRef}>
                                            {data.serviceByIdOrSlug.endpoints.some(e => e.quantity) && <FcfcTickets {...{serviceById: data.serviceByIdOrSlug}}/>}
                                            {!data.serviceByIdOrSlug.endpoints.some(e => e.quantity) && <div>Квитки закінчилися</div>}
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <br/>
                    <p className='font-size-0dot8rem'><FormattedMessage defaultMessage='* After payment, a letter with a QR code will be sent to your e-mail address, which must be presented at the entrance. Please note that in the case of using the Gmail mail service, the letter with the ticket may end up in the "Alerts", "Promotions" or "Social networks" folder.'/></p>
                </div>
            </div>
        </div>
        </>
    )
};

export default React.memo(() => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    const [showTicketsBoxBtn, setShowTicketsBoxBtn] = React.useState(false);
    const ticketsBoxRef = React.useRef();
    const scrollToTickets = () => {
        ticketsBoxRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }
    return (
        <div className='fcfc black'>
            <div className='view'>
                <div className='block'>
                    <div className='padding-top-1rem padding-bottom-1rem mw768-padding-top-1dot25rem mw768-padding-bottom-1dot25rem display-flex justify-content-space-between align-items-center'>
                        <Link to={`/events${searchFilter(location.search)}`}>
                            <LogoWhiteDarkModeTrue className='height-2rem'/>
                        </Link>
                        {showTicketsBoxBtn &&
                            <div className='border-radius-0dot5rem border-1px-solid border-color-gray-300 box-shadow-xs text-sm medium color-white padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot875rem padding-right-0dot875rem cursor-pointer' onClick={() => scrollToTickets()}>
                                <FormattedMessage defaultMessage='Tickets'/>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <ErrorBoundary {...{FallbackComponent: ServiceNotFound('dark')}}>
                    <React.Suspense fallback={
                        <div className='view'>
                            <div className='block'>
                                <Skeleton/>
                            </div>
                        </div>
                    }>
                        <Fcfc {...{setShowTicketsBoxBtn, ticketsBoxRef}}/>
                    </React.Suspense>
                </ErrorBoundary>
            </ErrorBoundary>
            <div className='view'>
                <div className='block'>
                    <div className='fcfc-footer margin-top-4rem border-top-1px-solid border-color-gray-600 padding-top-2rem mw768-padding-top-3rem padding-bottom-2rem mw768-padding-bottom-3rem'>
                        <Link to={`/events${searchFilter(location.search)}`}>
                            <LogoWhiteDarkModeTrue className='height-2rem'/>
                        </Link>
                        <div className='display-flex flex-direction-column-row padding-top-1dot5rem mw768-padding-top-2rem'>
                            <div className='flex-1'>
                                <div className='margin-bottom-0dot5rem'>
                                    <a className='text-md medium color-gray-200' href={`tel:${SUPPORT_CONTACT.cell}`} data-gtm='warp support-phone'>
                                        {SUPPORT_CONTACT.cellFormatted}
                                    </a>
                                </div>
                                <div>
                                    <a className='text-md medium color-primary-500' href={`mailto:${CONTACT_EMAIL}?subject=Warp support`} data-gtm='warp email'>{CONTACT_EMAIL}</a>
                                </div>
                            </div>
                            <div className='mw768-align-self-flex-end display-flex padding-top-2rem mw768-padding-top-0'>
                                <IconMaster className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                <IconVisa className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                <IconApplePay className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                <IconGooglePay className='display-block height-2rem mw768-height-2dot5rem'/>
                            </div>
                        </div>
                        <div className='display-flex flex-direction-column-row padding-top-1dot5rem mw768-padding-top-2rem margin-top-2rem mw768-margin-top-4rem border-top-1px-solid border-color-gray-600'>
                            <div className='flex-order-2 mw768-flex-order-1 flex-1 padding-top-1rem mw768-padding-top-0'>
                                <p className='text-md color-gray-200'>© {year} Ottry. All rights reserved.</p>
                            </div>
                            <div className='flex-order-1 mw768-flex-order-2 display-flex flex-wrap-wrap gap-0dot5rem-1rem'>
                                <Link className='text-md color-gray-200' to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                                <Link className='text-md color-gray-200' to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                                <Link className='text-md color-gray-200' to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                                <a className='text-md color-gray-200' href={TICKETS_CONTACT_FORM} target='_blank'><FormattedMessage defaultMessage='I want to sell online'/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});